import { rootId } from '@/constants'
import { isBrowser } from '@/lib/utils'
import React from 'react'

export default function useRootElement() {
  const ref = React.useRef(null)
  ref.current = React.useMemo(() => {
    return isBrowser ? document.getElementById(rootId) : null
  }, [])

  return ref
}
