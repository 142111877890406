import { rootId } from '@/constants'
import { isBrowser } from '@/lib/utils'
import { Portal } from '@chakra-ui/react'
import React from 'react'

// Make sure the Portal component uses our root element as the default container
const rootEl = isBrowser ? document.getElementById(rootId) : null
let containerRef

if (rootEl) {
  containerRef = React.createRef()
  containerRef.current = rootEl
}

Portal.defaultProps = {
  containerRef
}
