export const Box = {
  variants: {
    legend: {
      padding: 2,
      borderRadius: 'md',
      border: '1px',
      borderColor: 'gray.300'
    },
    card: {
      borderRadius: 'lg',
      boxShadow: 'lg',
      borderWidth: '1px',
      borderColor: 'gray.200'
    },
    sideBar: {
      flex: { base: 1, lg: 2 }
    },
    infoPanel: {
      flex: { base: 1, lg: 2, xl: 4 },
      marginRight: { base: 0, md: 5, lg: 10, xl: 20 }
    },
    mobileProperty: {
      px: { base: 4, md: 0 },
      py: 7
    }
  }
}
