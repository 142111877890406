import { useTrackPageViews } from '@/hooks/useTrackPageViews'
import camelcaseKeys from 'camelcase-keys'
import { createContext, useContext } from 'react'
const PageContext = createContext(undefined)

/**
 *
 * @param children
 * @param {SignalSettings} settings
 * @return {JSX.Element}
 * @constructor
 */
const PageContextProvider = ({ children, value = {} }) => {
  const ctxValue = { ...camelcaseKeys(value) }
  useTrackPageViews(ctxValue.seo)

  return (
    <PageContext.Provider value={ctxValue}>{children}</PageContext.Provider>
  )
}
export default PageContextProvider

export const usePageContext = () => {
  const context = useContext(PageContext)
  if (context === undefined)
    throw new Error('Context must be defined within a provider')
  return context
}
