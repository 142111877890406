const colors = {
  beyond: {
    200: '#F2FAFB',
    300: '#E5F3F7',
    400: '#69C5CD',
    500: '#43B6C0',
    600: '#2BA8B3',
    700: '#02556C'
  },
  gray: {
    100: '#F6F7F9',
    200: '#e9e9e9',
    300: '#d8d8d8',
    400: '#bfbfbf',
    500: '#a8a8a8',
    600: '#868686',
    700: '#464646',
    800: '#333333'
  },
  redStripe: {
    100: '#FF0000'
  },
  warning: {
    100: '#FEF4EB',
    200: '#F99038'
  },
  danger: {
    100: '#FDF6F9',
    200: '#e53e3e'
  },
  wpMain: {
    primary: 'var(--main_color-primary)',
    secondary: 'var(--main_color-secondary)',
    color: 'var(--main_color-color)',
    meta: 'var(--main_color-meta)',
    border: 'var(--main_color-border)',
    heading: 'var(--main_color-heading)',
    bg: 'var(--main_color-bg)',
    bg2: 'var(--main_color-bg2)'
  },
  wpHeader: {
    primary: 'var(--header_color-primary)'
  },
  // Used for colorScheme option
  wpMainScheme: {
    400: 'var(--main_color-primary)',
    500: 'var(--main_color-primary)',
    600: 'var(--main_color-primary)'
  },
  demoCtaButton: {
    400: '#69C5CD',
    500: '#69C5CD',
    600: '#69C5CD'
  },
  wpHeaderScheme: {
    400: 'var(--header_color-primary)',
    500: 'var(--header_color-primary)',
    600: 'var(--header_color-primary)'
  },
  banner: {
    payment: 'linear-gradient(90deg, rgba(228, 131, 166, 0.30) 0%, rgba(157, 83, 160, 0.30) 100%)'
  }
}

export default colors
