import { createContext, useContext } from 'react'
import { useBeaconConnector } from './useBeaconConnector'

const BeaconContext = createContext(undefined)

export const BeaconContextProvider = ({ pageProps, children }) => {
  const beacon = useBeaconConnector(pageProps)
  return (
    <BeaconContext.Provider value={beacon}>{children}</BeaconContext.Provider>
  )
}
export default BeaconContextProvider

export const useBeacon = () => {
  const context = useContext(BeaconContext)
  if (context === undefined)
    throw new Error('Context must be defined within a provider')
  return context
}
