import React from 'react'

/**
 * Dispatch a custom dom event on the ref element provided
 */
export function useSendEvent(ref) {
  return React.useCallback(
    function sendEvent(data) {
      if (ref.current) {
        const event = new CustomEvent('SIGNAL_EVENT', {
          detail: {
            ...data
          }
        })
        ref.current.dispatchEvent(event)
      }
    },
    [ref]
  )
}
