// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'
import getConfig from 'next/config'

const { publicRuntimeConfig: config } = getConfig()
const dsn = process.env.SENTRY_DSN ?? process.env.NEXT_PUBLIC_SENTRY_DSN
const environment = config.appEnv ?? process.env.NODE_ENV

Sentry.init({
  environment,
  dsn,
  sampleRate: 1,
  attachStacktrace: true,
  autoSessionTracking: false
})
