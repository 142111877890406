import { format, getDay, parse, startOfWeek } from 'date-fns'
import * as locales from 'date-fns/locale'

export const getRequestLocale = (req) => {
  if (
    !req?.headers['accept-language'] ||
    req?.headers['accept-language'] === ''
  ) {
    return 'en-US'
  }
  return req.headers['accept-language'].split(',')[0].trim()
}

export function getLocale(reqLocale) {
  const storedLocale = localStorage.getItem('locale')
  if (storedLocale && storedLocale !== 'undefined') {
    return storedLocale
  }

  return reqLocale || 'en-US'
}

export const localeFromISODate = (date, locale) => formatDate(date, locale, 'P')

export const getLocaleStartOfWeek = (locale) =>
  getDay(
    startOfWeek(new Date(), {
      locale: locales[locale.split('-').join('')] || locales['enUS']
    })
  )

export const getDateFormatFromLocale = (locale) => {
  if (!locales[locale.split('-').join('')]) {
    return false
  }

  return locales[locale.split('-').join('')].formatLong.date({ width: 'short' })
}

export const formatDate = (
  date,
  locale,
  pattern,
  { parseFormat = 'yyyy-MM-dd' } = {}
) =>
  format(parse(date, parseFormat, new Date()), pattern, {
    locale:
      locales[locale.split('-').join('')] ??
      locales[locale.substring(0, 2)] ??
      locales['enUS']
  })
