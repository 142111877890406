import logger from '@/lib/logger'
import { setCookie } from 'cookies-next'
import {
  genDeviceToken,
  genSessionToken,
  genTransactionToken
} from '../requestId'

// Sets the request id in the current session. Uses the one set in cookies.
// If unavailable generates a new one.
export function getRequestId(ctx) {
  const { req, res } = ctx

  let deviceToken = ''
  if (req?.cookies?.['deviceToken']) {
    deviceToken = req.cookies?.['deviceToken']
  } else {
    deviceToken = genDeviceToken()
    setCookie('deviceToken', deviceToken, { res, req })
  }

  let sessionToken = ''
  if (req?.cookies?.['sessionToken']) {
    sessionToken = req.cookies?.['sessionToken']
  } else {
    sessionToken = genSessionToken()
    setCookie('sessionToken', sessionToken, { res, req })
  }

  return { deviceToken, sessionToken, transactionToken: genTransactionToken() }
}

export const formatRequestId = (requestId) => {
  if (requestId) {
    return `${requestId.sessionToken}-${requestId.deviceToken}-${requestId.transactionToken}`
  }
  return null
}

export const setRequestMeta = ({ req, res, engineId }) => {
  const requestId = getRequestId({ req, res })
  // Enhance the request object with logger and requestId
  req.log = logger.child({
    requestId: formatRequestId(requestId),
    engineId
  })
  req._requestId = requestId
}

export const logStart = (req) => {
  req._startTime = new Date().getTime()
  req.log.info(
    { url: req.url, action: 'handler.start' },
    `Handler start ${req.url}`
  )
}

export const logEnd = (req) => {
  const responseTime = new Date().getTime() - req._startTime
  req.log.info(
    {
      url: req.url,
      responseTime,
      action: 'handler.end'
    },
    `Handler end ${req.url} - ${responseTime} ms`
  )
}
