import CSSReset from '@/components/utilites/CSSReset'
import { rootId } from '@/constants'
import { EmotionCacheProvider } from '@/lib/emotion'
import demoStyles from '@/styles/demo'
import theme from '@/theme'
import { Box, ChakraProvider, Flex } from '@chakra-ui/react'
import Footer from './Footer'
import Header from './Header'

function StandaloneWrapper({ children }) {
  return (
    <ChakraProvider theme={theme}>
      <style jsx global>
        {demoStyles}
      </style>
      <Flex direction="column" minH="full">
        <Header />
        <Box id="standalone_body" flex={1} minHeight="1200px">
          {children}
        </Box>
        <Footer />
      </Flex>
    </ChakraProvider>
  )
}

function EmbeddedWrapper({ children }) {
  return (
    <ChakraProvider theme={theme} resetCSS={false}>
      <CSSReset />
      {children}
    </ChakraProvider>
  )
}

export default function AppWrapper({ isStandalone, requestId, ...props }) {
  return (
    <div id={rootId} style={{ height: '100%' }}>
      <div className="request-id" style={{ display: 'none' }}>
        {requestId || ''}
      </div>
      <EmotionCacheProvider>
        {isStandalone ? (
          <StandaloneWrapper {...props} />
        ) : (
          <EmbeddedWrapper {...props} />
        )}
      </EmotionCacheProvider>
    </div>
  )
}
