import css from 'styled-jsx/css'

export default css.global`
  body {
    height: 100% !important;
  }

  body > #__next {
    height: 100%;
  }

  :root {
    --header_color-bg: #ffffff;
    --header_color-bg2: #5d5fef;
    --header_color-primary: #5d5fef;
    --header_color-secondary: #5d5fef;
    --header_color-color: #6f3b97;
    --header_color-meta: #000000;
    --header_color-heading: #252f38;
    --header_color-border: #fbfbff;
    --header_color-img: ;
    --header_color-customimage: ;
    --header_color-pos: top left;
    --header_color-repeat: no-repeat;
    --header_color-attach: scroll;
    --main_color-bg: #ffffff;
    --main_color-bg2: #ffffff;
    --main_color-primary: #5d5fef;
    --main_color-secondary: #9980b1;
    --main_color-color: #121212;
    --main_color-meta: #121212;
    --main_color-heading: #111111;
    --main_color-border: #cccccc;
    --main_color-img: ;
    --main_color-customimage: ;
    --main_color-pos: top left;
    --main_color-repeat: no-repeat;
    --main_color-attach: scroll;
    --alternate_color-bg: #ffffff;
    --alternate_color-bg2: #ffffff;
    --alternate_color-primary: #5d5fef;
    --alternate_color-secondary: #9980b1;
    --alternate_color-color: #121212;
    --alternate_color-meta: #121212;
    --alternate_color-heading: #111111;
    --alternate_color-border: #cccccc;
    --alternate_color-img: ;
    --alternate_color-customimage: ;
    --alternate_color-pos: top left;
    --alternate_color-repeat: no-repeat;
    --alternate_color-attach: scroll;
    --footer_color-bg: #343f53;
    --footer_color-bg2: #343f53;
    --footer_color-primary: #ffffff;
    --footer_color-secondary: #5d5fef;
    --footer_color-color: #ffffff;
    --footer_color-meta: #ffffff;
    --footer_color-heading: #ffffff;
    --footer_color-border: #343f53;
    --footer_color-img: ;
    --footer_color-customimage: ;
    --footer_color-pos: top left;
    --footer_color-repeat: no-repeat;
    --footer_color-attach: scroll;
    --socket_color-bg: #343f53;
    --socket_color-bg2: #343f53;
    --socket_color-primary: #ffffff;
    --socket_color-secondary: #5d5fef;
    --socket_color-color: #ffffff;
    --socket_color-meta: #ffffff;
    --socket_color-heading: #ffffff;
    --socket_color-border: #343f53;
    --socket_color-img: ;
    --socket_color-customimage: ;
    --socket_color-pos: top left;
    --socket_color-repeat: no-repeat;
    --socket_color-attach: scroll;
  }
`
