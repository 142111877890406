import { useNProgress } from '@tanem/react-nprogress'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

export default function NProgress() {
  const router = useRouter()

  const [state, setState] = useState({
    isRouteChanging: false,
    loadingKey: 0
  })

  useEffect(() => {
    const handleRouteChangeStart = () => {
      setState((prevState) => ({
        ...prevState,
        isRouteChanging: true,
        loadingKey: prevState.loadingKey ^ 1
      }))
    }

    const handleRouteChangeEnd = () => {
      setState((prevState) => ({
        ...prevState,
        isRouteChanging: false
      }))
    }

    router.events.on('routeChangeStart', handleRouteChangeStart)
    router.events.on('routeChangeComplete', handleRouteChangeEnd)
    router.events.on('routeChangeError', handleRouteChangeEnd)

    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart)
      router.events.off('routeChangeComplete', handleRouteChangeEnd)
      router.events.off('routeChangeError', handleRouteChangeEnd)
    }
  }, [router.events])

  return (
    <Loading isRouteChanging={state.isRouteChanging} key={state.loadingKey} />
  )
}

function Loading({ isRouteChanging }) {
  const { animationDuration, isFinished, progress } = useNProgress({
    isAnimating: isRouteChanging
  })

  return (
    <>
      <style jsx>{`
        .ng-container {
          opacity: ${isFinished ? 0 : 1};
          pointer-events: none;
          transition: opacity ${animationDuration}ms linear;
          overflow: hidden;
          height: 6px;
          position: fixed;
          top: 0;
          width: 100%;
          z-index: 1000;
        }
        .ng-bar {
          background: var(--main_color-secondary);
          height: 3px;
          left: 0;
          margin-left: ${(-1 + progress) * 100}%;
          position: absolute;
          top: 0;
          transition: margin-left ${animationDuration}ms linear;
          width: 100%;
          z-index: 1031;
        }
        .ng-peg {
          display: block;
          position: absolute;
          right: 0px;
          width: 100px;
          height: 100%;
          box-shadow: 0 0 10px var(--main_color-secondary),
            0 0 5px var(--main_color-secondary);
          opacity: 1;

          -webkit-transform: rotate(3deg) translate(0px, -4px);
          -ms-transform: rotate(3deg) translate(0px, -4px);
          transform: rotate(3deg) translate(0px, -4px);
        }
      `}</style>
      <div className="ng-container">
        <div className="ng-bar">
          <div className="ng-peg" />
        </div>
      </div>
    </>
  )
}
