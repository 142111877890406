import getConfig from 'next/config'
import pino from 'pino'
import { isBrowser } from './utils'

const { publicRuntimeConfig: config } = getConfig()
const env = config.appEnv ?? process.env.NODE_ENV

const PINO_LOG_LEVEL = isBrowser
  ? localStorage.getItem('PINO_LOG_LEVEL') ?? 'warn'
  : process.env.PINO_LOG_LEVEL ?? 'info'

const logger = pino({
  level: PINO_LOG_LEVEL,
  messageKey: 'message',
  hooks: {
    logMethod(args, method) {
      const { requestId } = this.bindings() ?? {}
      if (requestId) {
        if (typeof args[0] === 'string') {
          args[0] = `${requestId} ${args[0]}`
        } else if (typeof args[1] === 'string') {
          args[1] = `${requestId} ${args[1]}`
        }
      }
      return method.apply(this, args)
    }
  },
  formatters: {
    level: (label) => ({
      level: label.toUpperCase()
    })
  },
  ...(env === 'development' && {
    transport: {
      target: 'pino-pretty',
      options: {
        colorize: 'true',
        messageKey: 'message'
      }
    }
  })
})

export default logger
