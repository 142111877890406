import { DataError } from '@/constants/errors'
import { differenceInDays, isEqual, startOfMonth } from 'date-fns'
import dayjs from 'dayjs'
import getConfig from 'next/config'
import Geocode from 'react-geocode'
import { getRequestLocale } from './localeUtils'

const { googleMapsApiKey } = getConfig().publicRuntimeConfig
const { defaultRootSlug = '' } = getConfig().serverRuntimeConfig
const signalClientPrefix = process.env.NEXT_PUBLIC_SIGNAL_CLIENT_PREFIX

export const isBrowser = typeof window !== 'undefined'

/**
 * parse request headers to get the authToken or parse from url in standalon mode
 * @param req
 * @return {string}
 */
export const getAuthToken = (req, query) => {
  const authHeader = req?.headers?.authorization ?? query.engineId ?? ''
  return authHeader.replace('Bearer ', '')
}

/**
 * Parse the headers to get the rootSlug
 * @param  req The incoming request
 * @returns {string} output
 */
export const getRootSlug = (req) =>
  req?.headers?.['x-root-slug']?.replace(/\/$/, '') ?? defaultRootSlug

/**
 * Infer standalone mode from query params
 * @param  req The incoming request
 * @returns {string} output
 */
export const getStandalone = (query) => !!query.engineId

/**
 * Get the parameters used in the signal context
 * @param  req The incoming request
 * @returns {SignalContext} signal context with apiToken, rootSlug
 */
export const getSignalContext = ({ req, query }) => {
  const token = getAuthToken(req, query)
  return {
    apiToken: token,
    rootSlug: getRootSlug(req),
    reqLocale: getRequestLocale(req),
    standalone: getStandalone(query),
    token
  }
}

/**
 * Returns the beyondpricing image url for converting the image to the correct size
 * @param {UploadImageSize | undefined} uploadImageSizeSetting the setting in the signal-db
 * @param {string} url the image url to pull from beyond
 * @param {string} size the key to pull from the uploadImageSizeSettings
 * @returns {string} the url to pull from https://image-proxy.beyondpricing.com/
 */
export const getCDNImageUrl = (uploadImageSizeSetting, url, size) => {
  const outputSize = uploadImageSizeSetting[size]
  if (!outputSize) throw Error(`Unable to get size=${size}`)
  return `https://image-proxy.beyondpricing.com/${outputSize.width}x${outputSize.height}/${url}`
}

/**
 * Given a number N, returns a string..the suffix "st" or "nd"
 * @param {number} n the number for which we want a suffix
 * @returns {String} 'st' if n === 1, or 'nd' if n > 1
 */
export const getSuffixForN = (n) => {
  const suffixes = {
    0: '',
    1: 'st',
    2: 'nd',
    3: 'rd',
    4: 'th'
  }

  // any n argument > than 5 gets 'th'
  return suffixes[n] ? suffixes[n] : 'th'
}

/**
 * Store these keys better.  Can we hide them better then access from server-side?  Research
 */
Geocode.setApiKey(googleMapsApiKey)
export const addressToLatLng = async (address) => {
  return new Promise((resolve, reject) => {
    Geocode.fromAddress(
      `${address.address1}, ${address.city}, ${address.state}, ${address.zip}`
    ).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location
        resolve({ lat, lng })
      },
      (error) => {
        reject(error)
      }
    )
  })
}

const arrayOfKeys = (data) => {
  let filtered_data = data.split('{')[1]
  if (filtered_data){
    filtered_data = filtered_data.split('}')[0]
  }
  else
    filtered_data = ''
  
  return filtered_data.split(';').map((value) => {
    if (value.includes('"')) return value.split('"')[1]
    if (['b:1', 'yes'].includes(value)) return true
    if (['b:0', 'no'].includes(value)) return false
    return value
  })
}
export const jsonStringToObject = (data) => {
  const array = arrayOfKeys(data)
  const matrix = array.reduce(
    (rows, key, index) =>
      (index % 2 === 0 ? rows.push([key]) : rows[rows.length - 1].push(key)) &&
      rows,
    []
  )
  return matrix.reduce((acc, row) => {
    if (row.length === 2) {
      const [key, value] = row
      acc[key] = value ?? null
    }
    return acc
  }, {})
}

export const breakdownImageSizes = (data) => {
  return data.split(',').reduce((acc, cur) => {
    const [key, ...values] = cur.trim().split(':')
    const [width, height] = values[0].split('x')
    acc[key] = { width: parseInt(width, 10), height: parseInt(height, 10) }
    return acc
  }, {})
}
export const breakdownMapZoom = (data) => {
  const obj = jsonStringToObject(data)
  return Object.entries(obj).reduce((acc, [key, value]) => {
    acc[key] = Number(value)
    return acc
  }, {})
}

const breakDownEngineSettingsLookup = {
  defaultSeo: jsonStringToObject,
  propertyCalendar: jsonStringToObject,
  uploadImageSize: breakdownImageSizes,
  mapZoom: breakdownMapZoom
}

/**
 * snake_case to camelCase
 * @param {string} str
 * @return {string}
 */
export const snakeToCamel = (str) =>
  str.replace(/([-_]\w)/g, (g) => g[1].toUpperCase())

/**
 * Return a boolean if string is 'yes' or 'no'
 * @param {*} val
 * @returns {boolean|*}
 */
export const checkForBoolean = (val) => {
  if (['no', 'yes'].includes(val)) {
    return val === 'yes'
  }
  return val
}
/**
 *
 * @param {Array} settings
 * @return {SignalSettings}
 */
export const parseSettings = (settings) => {
  if (!settings) throw new Error('Unable to get settings!')
  return settings.reduce((acc, cur) => {
    const key = snakeToCamel(cur.setting)
    acc[key] = checkForBoolean(cur.value)
    if (key in breakDownEngineSettingsLookup) {
      const breakdownFunction = breakDownEngineSettingsLookup[key]
      acc[key] = breakdownFunction(cur.value)
    }
    return acc
  }, {})
}

/**
 *
 * @param data
 * @param pageName
 * @return {{settings: SignalSettings, seo: SignalSeo}}
 */
export const convertEngineResponse = (data, pageName) => {
  let attributes = data
  if ('attributes' in data) attributes = data.attributes
  const engineId = attributes.public_id
  if (!attributes) {
    throw new DataError('Unable to find attributes in engine response')
  }

  const demoSettings = [{ setting: 'demo', value: attributes?.demo }]

  const returnData = {
    seo: attributes?.seo_rules?.find((r) => r.page_name === pageName),
    settings: parseSettings(attributes.settings.concat(demoSettings)),
    engineId
  }
  if (!returnData?.seo) {
    throw new DataError(`Unable to find seo for pageName=${pageName}`)
  }
  return returnData
}

export const convertPageResponse = (data) => {
  let attributes = data
  if ('attributes' in data) attributes = data.attributes
  if (!attributes) {
    throw new DataError('Unable to find attributes in page response')
  }
  let pageSettings = attributes.settings
  attributes.settings = parseSettings(pageSettings)
  return attributes
}
/**
 * remove null or empty string in accumulator function
 * @param {{}} acc the output accumulator
 * @param {string} key
 * @param value
 * @return {*}
 */
export const removeNullOrEmptyString = (acc, [key, value]) => {
  if (!value && !Number.isFinite(value)) return acc
  acc[key] = value
  return acc
}

/**
 * getToday midnight using dayjs
 * @return {Date}
 */
export const getToday = () =>
  dayjs().second(0).minute(0).hour(0).millisecond(0).toDate()

/**
 *
 * @return {dayjs.Dayjs}
 */
export const getStartOfMonth = () =>
  dayjs(getToday()).date(1).minute(0).second(0)
/**
 *
 * @return {boolean}
 */
export const isStartOfMonth = () =>
  isEqual(startOfMonth(new Date()), new Date())

/**
 *
 * @param {dayjs.Dayjs} start
 * @param {dayjs.Dayjs} end
 * @return {dayjs.Dayjs[]}
 */
export const expandDates = (start, end) => {
  let arr = []
  let dt = dayjs(start.toDate())
  while (dt <= end) {
    arr.push(dt)
    dt = dt.add(1, 'day')
  }
  return arr
}

/**
 *
 * @param {{from: Date, to: Date| undefined}} dates
 * @param {Date} selectedDay
 * @return {{from: Date, to: Date | undefined}}
 */
export const selectDateRange = (dates, selectedDay) => {
  let newDates = { from: selectedDay, to: null }
  if (!dates.to) {
    if (isEqual(selectedDay, dates.from))
      return { from: dates.from, to: dates.to }
    if (differenceInDays(selectedDay, dates.from) > 0)
      return { ...dates, to: selectedDay }
    return { from: selectedDay, to: dates.from }
  }
  return newDates
}

/**
 *
 * @param {string | null} checkin
 * @param {string | null} checkout
 * @return {{checkoutDate: dayjs.Dayjs | null, isValid: boolean, checkinDate: dayjs.Dayjs | null}}
 */
export const checkInCheckOutIsValid = (checkin, checkout) => {
  if (!checkin || !checkout)
    return {
      checkinDate: null,
      checkoutDate: null,
      isValid: false
    }
  const checkinDate = dayjs(checkin)
  const checkoutDate = dayjs(checkout)
  const isValid = checkinDate.isValid() && checkoutDate.isValid()
  return {
    checkinDate,
    checkoutDate,
    isValid
  }
}
/**
 *
 * @param {string | null} checkin
 * @param {string | null} checkout
 * @return {{checkoutDate: Date, isValid: boolean, checkinDate: Date}
 * |{checkoutDate: null, isValid: boolean, checkinDate: null}}
 */
export const checkInCheckoutIsValidToDate = (checkin, checkout) => {
  const temp = checkInCheckOutIsValid(checkin, checkout)
  if (temp.isValid)
    return {
      ...temp,
      checkoutDate: temp.checkoutDate.toDate(),
      checkinDate: temp.checkinDate.toDate()
    }
  return temp
}

export const generateBeyondImageLoader =
  (settings, size = 'medium') =>
    (props) =>
      getCDNImageUrl(settings.uploadImageSize, props.src, size)

export const createDisplayImage = (image, settings) => {
  if (!image) return {}
  return {
    url: getCDNImageUrl(settings.uploadImageSize, image.full_url, 'medium'),
    full_url: image.full_url,
    alt: image.alt_text,
    title: image.image_description || image.title_text
  }
}

/**
 *
 * @param {boolean} hide
 * @return {undefined}
 */

export const hideScrollBar = (hide) => {
  const { gap } = getScrollGapWidth()
  const header = document.getElementById('header')
  const standalone = document.getElementById('standalone_body')

  if (hide) {
    if (!standalone) {
      if (header) header.style.paddingRight = `${gap}px`
      document.documentElement.style.overflowY = 'hidden'
    }

    document.getElementById('signal-be-client').style.paddingRight = `${gap}px`
    document.body.style.overflowY = 'hidden'
  } else {
    if (!standalone) {
      if (header) header.style.paddingRight = 0
      document.documentElement.style.overflowY = 'visible'
    }

    document.getElementById('signal-be-client').style.paddingRight = 0
    document.body.style.overflowY = 'visible'
  }
}

/**
 *
 * @param {string} gapMode
 * @return {array}
 */
const getScrollOffset = (gapMode) => {
  const parse = (x) => parseInt(x || '', 10) || 0
  const cs = window.getComputedStyle(document.body)
  const left = cs[gapMode === 'padding' ? 'paddingLeft' : 'marginLeft']
  const top = cs[gapMode === 'padding' ? 'paddingTop' : 'marginTop']
  const right = cs[gapMode === 'padding' ? 'paddingRight' : 'marginRight']
  return [parse(left), parse(top), parse(right)]
}

/**
 *
 * @param {string} gapMode
 * @return {object}
 */
export const getScrollGapWidth = (gapMode = 'margin') => {
  if (typeof window === 'undefined') {
    return {
      left: 0,
      top: 0,
      right: 0,
      gap: 0
    }
  }
  const offsets = getScrollOffset(gapMode)
  const documentWidth = document.documentElement.clientWidth
  const windowWidth = window.innerWidth

  return {
    left: offsets[0],
    top: offsets[1],
    right: offsets[2],
    gap: Math.max(0, windowWidth - documentWidth + offsets[2] - offsets[0])
  }
}

// Remove trailing slash from string
export function stripTrailingSlash(str) {
  return str.replace(/\/+$/, '')
}

/**
 * Strip basePath from path
 * @param {string} rootSlug rootSlug
 * @param {string} path relative path
 * @returns path without basePath
 */
export function stripBasePath({ standalone, token, rootSlug, path }) {
  return standalone
    ? path.split('?')[0].replace(`/${token}`, '').replace(/\/$/, '')
    : path
      .split('?')[0]
      .replace(`/${rootSlug}`, '')
      .replace(`${signalClientPrefix}`, '')
      .replace(/\/$/, '')
}

//remove # elements from tab naviation
//remove extra slashes in param
export function cleanParams(query) {
  const cleanedQuery = {}
  for (const param in query) {
    cleanedQuery[param] = query[param].split('#')[0].split('/')[0]
  }
  return cleanedQuery
}

//get max page height
export function getMaxPageHeight() {
  return Math.max(
    document.body.scrollHeight,
    document.body.offsetHeight,
    document.documentElement.clientHeight,
    document.documentElement.scrollHeight,
    document.documentElement.offsetHeight
  )
}

export function sortByKey(array, key) {
  return array.sort(function (a, b) {
    var x = a[key]
    var y = b[key]
    return x < y ? -1 : x > y ? 1 : 0
  })
}
