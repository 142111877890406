import logger from '@/lib/logger'
import {
  convertEngineResponse,
  convertPageResponse,
  isBrowser
} from '@/lib/utils'
import { captureMessage } from '@sentry/nextjs'
import axios from 'axios'
import omitBy from 'lodash/omitBy'
import getConfig from 'next/config'
export default axios

const _10minutes = 1000 * 60 * 10
const _1hour = 1000 * 60 * 60

const { publicRuntimeConfig } = getConfig()

export const getBeyondUrl = (beyondUrl) => {
  if (!beyondUrl) {
    throw new Error('Unable to get signalBeyondAppUrl!')
  }
  return `${beyondUrl}/api/signal/be`
}

export const createBaseApiClient = (axiosInstance, ctx) => {
  const log = ctx?.req?.log ?? logger
  const reqId = ctx?.req?._requestId

  axiosInstance.interceptors.request.use((config) => {
    config.meta = config.meta ?? {}
    config.meta.requestStartedAt = new Date().getTime()
    if (reqId) {
      config.headers[
        'prefixRequestId'
      ] = `${reqId.sessionToken}-${reqId.deviceToken}-${reqId.transactionToken}`
    }
    log.info(
      {
        baseUrl: config.baseURL,
        method: config.method,
        url: config.url
      },
      `external request started - ${config.url}`
    )
    return config
  })

  axiosInstance.interceptors.response.use((resp) => {
    const delta = new Date().getTime() - resp.config.meta.requestStartedAt
    log.info(
      {
        baseUrl: resp.config.baseURL,
        method: resp.config.method,
        url: resp.config.url,
        status: resp.status,
        responseTime: delta
      },
      `external request completed - ${resp.config.url} - ${delta} ms`
    )
    return resp
  })

  return axiosInstance
}

/**
 *
 * @param data
 * @param slug
 * @return {BasePropertyResponse}
 */
const mapDataToProperty = (data, slug) => {
  let property = data
  // noinspection JSValidateTypes
  return {
    pid: property.id,
    slug: slug,
    ...property
  }
}

export const getBaseApi = (api) => {
  return {
    applePay: {
      validateMerchant: (
        engineId,
        pid,
        { domain, displayName, validationUrl }
      ) => {
        const path = `/${engineId}/properties/${pid}/validate-apple-pay`
        const payload = {
          domain,
          display_name: displayName,
          validation_url: validationUrl
        }
        return api.post(path, payload).then((resp) => resp.data)
      }
    },
    bookProperty: (engineId, { ...params }) => {
      // Unpack signature params
      const {
        pid,
        checkin,
        checkout,
        adults,
        children,
        pets,
        addons,
        promo_code,
        quote_total,
        guest_data,
        payment_data,
        guest_id,
        quote_id,
        pet_details,
        travel_insurance_selected,
        ga_session_id
      } = params

      // Build payload to send
      const payload = {
        checkin,
        checkout,
        adults,
        children,
        pets,
        addons,
        promo_code,
        quote_total,
        guest_data,
        payment_data,
        guest_id,
        quote_id,
        pet_details,
        travel_insurance_selected,
        ga_session_id
      }

      // Proxy through Next if we're in the browser
      const config = isBrowser
        ? { baseURL: `${publicRuntimeConfig.signalBeClientUrl}/api` }
        : {}

      const path = `/${engineId}/properties/${pid}/book`

      if (Object.values(payload).filter(Boolean).length === 0) {
        captureMessage('Empty payload', {
          extra: {
            payload,
            pid,
            engineId,
            config
          }
        })
      }

      return api.post(path, payload, config).then(
        (resp) => resp.data,
        (error) => {
          return error.response?.data ?? { bookable: false }
        }
      )
    },

    getQuote: (engineId, { ...params }) => {
      // Unpack signature params
      const {
        pid,
        checkin,
        checkout,
        adults,
        children,
        pets,
        addons,
        promo_code,
        page,
        quote_id,
        pet_details
      } = params

      // Build params to send
      const queryParams = {
        checkin,
        checkout,
        adults,
        children,
        pets,
        addons,
        promo_code,
        page,
        quote_id,
        pet_details
      }

      const path = `/${engineId}/properties/${pid}/quote`

      return api.get(path, { params: queryParams }).then(
        (resp) => {
          return resp.data
        },
        (error) => {
          if (error.response?.status === 400) {
            return error.response.data
          }
          return { bookable: false }
        }
      )
    },

    getPropertyReviews: (engineId, pid, page = 1, page_size = 5) =>
      api
        .get(`/${engineId}/properties/${pid}/reviews`, {
          params: { page, page_size }
        })
        .then((r) => r.data),

    /**
     *
     * @param {string} engineId
     * @param {string} pageName
     * @return {Promise<{settings: SignalSettings, seo: SignalSeo}> | {settings: SignalSettings, seo: SignalSeo}}
     */
    getEngine: (engineId, pageName) =>
      api
        .get(`/${engineId}`, {
          cache: {
            maxAge: _10minutes
          }
        })
        .then((r) => ({
          ...convertEngineResponse(r.data, pageName),
          _fromCache: !!r.request.fromCache
        })),

    getProperty: (engineId, slug) =>
      api
        .get(`/${engineId}/properties/by-slug/${slug}`)
        .then((r) => mapDataToProperty(r.data, slug)),

    /**
     *
     * @param {string} engineId
     * @param {string} slug
     * @return {Promise<DetailedProperty>}
     */
    getPropertyDetails: (engineId, slug) =>
      api
        .get(`/${engineId}/properties/by-slug/${slug}/details`)
        .then((r) => mapDataToProperty(r.data, slug)),

    paymentConfiguration: (engineId, slug) => {
      const endpoint = `/${engineId}/properties/by-slug/${slug}/payment-configuration`
      return api.get(endpoint).then(
        (r) => {
          return r.data
        },
        (error) => {
          console.error(error)
          return null
        }
      )
    },

    getPropertyRates: (engineId, propertyId) => {
      const endpoint = `/${engineId}/properties/${propertyId}/daily-rates`
      return api.get(endpoint).then((r) => r.data)
    },

    postPropertyRequest: (engineId, propertyId, data) => {
      return api
        .post(`/${engineId}/properties/${propertyId}/send-request`, {
          ...data
        })
        .then((r) => r.data)
    },

    getAllProperties: (engineId) => {
      const endpoint = `/${engineId}/properties`
      return api.get(endpoint).then((r) => r.data)
    },

    searchProperties: (engineId, query = {}) => {
      let queryString = ''
      if (query) {
        const validParams = omitBy(query, (v) => v == null)
        if (!validParams.page_size) validParams.page_size = 10
        if (Object.keys(validParams).length) {
          queryString = `?${new URLSearchParams(validParams).toString()}`
        }
      }
      const endpoint = `/${engineId}/properties/search${queryString}`
      return api
        .get(endpoint)
        .then((r) => r.data)
        .catch((e) => console.log(e))
    },

    getPage: (engineId, pageId, paramsObj = {}) => {
      const params = new URLSearchParams(paramsObj)
      const endpoint = `/${engineId}/pages/${pageId}?${params.toString()}`
      return api.get(endpoint).then((r) => convertPageResponse(r.data))
    },

    postPropertyReview: (engineId, propertyId, data) => {
      return api.post(`/${engineId}/properties/${propertyId}/post-review`, {
        ...data
      })
    },

    submitEngineInterest: (engineId, isQuoteSupported) => {
      const URI = isQuoteSupported
        ? 'cta_pms_supported_customer_interested'
        : 'cta_pms_not_supported_customer_interested'
      return api.put(`/${engineId}/ctas/${URI}`).then((r) => r.data)
    },

    getEngineInterest: (engineId, isQuoteSupported) => {
      const URI = isQuoteSupported
        ? 'cta_pms_supported_customer_interested'
        : 'cta_pms_not_supported_customer_interested'
      return api.get(`/${engineId}/ctas/${URI}`).then((r) => r.data)
    },
    getAllReviews: (engineId, page, page_size) => {
      return api
        .get(`/${engineId}/reviews`, {
          params: { page, page_size }
        })
        .then((r) => r.data)
    },

    postFavoriteEmailRequest: (engineId, data) => {
      return api
        .post(`/${engineId}/favorites/send-favorites`, {
          ...data
        })
        .then((r) => r.data)
    },

    getAllReviewsSummary: (engineId) =>
      api
        .get(`/${engineId}/reviews/summary`, {
          cache: {
            maxAge: _1hour
          }
        })
        .then((r) => r.data)
  }
}
