import { AlertContainer } from '@/theme/components/AlertContainer'
import { Box } from '@/theme/components/Box'
import { Button } from '@/theme/components/Button'
import { Checkbox } from '@/theme/components/Checkbox'
import { Divider } from '@/theme/components/Divider'
import { Heading } from '@/theme/components/Heading'
import { Icon } from '@/theme/components/Icon'
import { Input } from '@/theme/components/Input'
import { Link } from '@/theme/components/Link'
import { NumberInput } from '@/theme/components/NumberInput'
import { Popover } from '@/theme/components/Popover'
import { Tabs } from '@/theme/components/Tabs'
import { Text } from '@/theme/components/Text'
import { Textarea } from '@/theme/components/Textarea'
import { extendTheme } from '@chakra-ui/react'
import './components/Portal'
import colors from './foundations/colors'
import typography from './foundations/typography'
import styles from './styles'

const extensions = {
  ...typography,
  styles,
  colors,
  breakpoints: {
    sm: '5px',
    md: '820px',
    lg: '1000px',
    xl: '1395px',
    '2xl': '1536px'
  },
  sizes: {
    container: {
      '2xl': '1450px'
    }
  },
  components: {
    AlertContainer,
    Button,
    Box,
    Divider,
    Heading,
    Icon,
    Link,
    Input,
    NumberInput,
    Popover,
    Text,
    Textarea,
    Checkbox,
    Tabs
  }
}

const theme = extendTheme(extensions)

export default theme
