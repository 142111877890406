export const Checkbox = {
  baseStyle: {
    control: {
      border: '1px solid',
      borderColor: 'gray.300'
    }
  },
  variants: {
    wordpress: {
      control: {
        bg: 'white',
        _checked: {
          bg: 'wpMain.primary',
          borderColor: 'wpMain.primary',

          _hover: {
            bg: 'wpMain.primary',
            borderColor: 'wpMain.primary'
          }
        }
      }
    }
  },
  defaultProps: {
    variant: 'wordpress'
  }
}
