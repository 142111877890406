import useRootElement from '@/hooks/useRootElement'
import { useRouter } from 'next/router'
import React from 'react'
import { useEvent } from 'react-use'
import { Events } from './constants'
import { useSendEvent } from './useSendEvent'

// Event queue before beacon is ready
// Maintains at most 10 events in the queue
function queueReducer(state, action) {
  switch (action.type) {
    case 'add':
      return [...state.slice(-10), action.payload]
    case 'set':
      return [action.payload]
    case 'clear':
      return []
    default:
      throw new Error()
  }
}

const add = (event) => ({ type: 'add', payload: event })
const set = (event) => ({ type: 'set', payload: event })
const clear = () => ({ type: 'clear' })
const pageQuote = (data) => add({ type: Events.QUOTE, ...data })
const pageBook = (data) => add({ type: Events.BOOK, ...data })
const pageChange = (data) => set({ type: Events.PAGE_CHANGE, ...data })

export function useBeaconConnector(pageProps) {
  const { asPath, query } = useRouter()
  const rootElRef = useRootElement()
  const sendEvent = useSendEvent(rootElRef)
  const [isConnected, setConnected] = React.useState(false)
  const [queue, dispatch] = React.useReducer(queueReducer, [])
  useEvent(Events.CONNECT, () => setConnected(true), rootElRef.current)

  const sendPageQuote = React.useCallback(
    (data, error) => dispatch(pageQuote({ data, error, pageProps })),
    [pageProps]
  )

  const sendPageBook = React.useCallback(
    (data, error) => dispatch(pageBook({ data, error, pageProps })),
    [pageProps]
  )

  // It's possible that Beacon loads before we attach to the connect event
  // in that case we check for the presence of the `window.__BEACON` variable
  React.useEffect(() => {
    if (window.__BEACON) {
      setConnected(true)
    }
  }, [])

  // Acknowledge Beacon connection
  React.useEffect(() => {
    if (isConnected) {
      sendEvent({ type: Events.CONNECT_ACK })
    }
  }, [isConnected, sendEvent])

  // Send events if payload changes or beacon is connected
  React.useEffect(() => {
    if (queue.length && isConnected) {
      queue.forEach((evt) => {
        sendEvent(evt)
      })
      dispatch(clear())
    }
  }, [queue, isConnected, sendEvent])

  // Hook into pageProps
  React.useEffect(() => {
    dispatch(
      pageChange({
        query,
        url: `${document.location.origin}${asPath}`,
        ...pageProps
      })
    )
  }, [pageProps, query, sendEvent, asPath])

  return React.useMemo(
    () => ({ sendPageQuote, sendPageBook }),
    [sendPageQuote, sendPageBook]
  )
}
