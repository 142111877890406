import { setCookie } from 'cookies-next'
import getConfig from 'next/config'
import { PostHog } from 'posthog-node'
import { v4 as uuidv4 } from 'uuid'
import { getAuthToken } from '../utils'
import { formatRequestId, getRequestId } from './context'

let client = null
export async function postHogInit({ req, query, res }) {
  if (process.env.NEXT_PUBLIC_APP_ENV === 'test') {
    return { featureFlags: {}, posthogUserId: null }
  }
  const requestId = getRequestId({ req, res })
  let posthogUserId = null
  const engineId = getAuthToken(req, query)
  const posthogApiKey = getConfig().publicRuntimeConfig.posthogApiKey

  if (!client) {
    client = new PostHog(posthogApiKey, {
      featureFlagsPollingInterval: 500
    })
  }

  if (!req.cookies.posthog_user_id) {
    posthogUserId = uuidv4()
    setCookie('posthog_user_id', posthogUserId, { res, req })

    client.identify({
      distinctId: posthogUserId,
      properties: {
        engineId,
        requestId: formatRequestId(requestId)
      }
    })
  } else {
    posthogUserId = req.cookies.posthog_user_id
  }

  const featureFlags = await client.getAllFlags(posthogUserId, {
    personProperties: { engineId }
  })

  return { featureFlags, posthogUserId }
}

export function postHogShutdown() {
  return client?.shutdown()
}
