import AppWrapper from '@/components/standalone/AppWrapper'
import PageContextProvider from '@/contexts/page'
import SignalContextProvider from '@/contexts/signal'
import { useRefreshSessionId } from '@/hooks/useRefreshSessionId'
import { BeaconContextProvider } from '@/lib/beacon'
import { formatRequestId, getRequestId } from '@/lib/server/context'
import { postHogInit } from '@/lib/server/posthog'
import { getSignalContext, isBrowser } from '@/lib/utils'
import * as Sentry from '@sentry/nextjs'
import { captureException } from '@sentry/nextjs'
import 'focus-visible/dist/focus-visible'
import { appWithTranslation } from 'next-i18next'
import App from 'next/app'
import Head from 'next/head'
import { SWRConfig } from 'swr'
import 'test/mocks'
import NProgress from '../components/core/NProgress'
import '../styles/apple-pay.css'
import '../styles/pagination.scss'
import '../styles/react-day-picker.scss'
import '../styles/searchbar-visibility.scss'
import '../styles/visibility.scss'

const MainApplication = ({
  Component,
  pageProps,
  signalContext,
  requestId
}) => {
  Sentry.setTag('engine_id', signalContext.token)
  useRefreshSessionId()
  const isDemo = !!pageProps.settings?.demo

  return (
    <SignalContextProvider
      signalContext={signalContext}
    >
      <PageContextProvider value={pageProps?.page}>
        <BeaconContextProvider pageProps={pageProps}>
          <SWRConfig>
            {isDemo && (
              <Head>
                <meta name="robots" content="noindex,nofollow" />
              </Head>
            )}
            <AppWrapper
              isStandalone={signalContext.standalone}
              requestId={requestId}
            >
              <NProgress />
              <Component {...pageProps} />
            </AppWrapper>
          </SWRConfig>
        </BeaconContextProvider>
      </PageContextProvider>
    </SignalContextProvider>
  )
}

MainApplication.getInitialProps = async (appContext) => {
  const appProps = await App.getInitialProps(appContext)
  const requestId = getRequestId(appContext.ctx)

  let featureFlags = {}
  let posthogUserId = null

  try {
    if (!isBrowser && appContext.ctx.req?.url !== '/healthz') {
      const ph = await postHogInit(appContext.ctx)
      featureFlags = ph.featureFlags
      posthogUserId = ph.posthogUserId
    }
  } catch (e) {
    console.log(e)
    captureException(e)
  }

  return {
    ...appProps,
    requestId: formatRequestId(requestId),
    signalContext: {
      ...getSignalContext(appContext.ctx),
      featureFlags,
      posthogUserId
    }
  }
}

export default appWithTranslation(MainApplication)
