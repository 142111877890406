// Start mock server on test environment
async function initMocks() {
  if (process.env.NEXT_PUBLIC_APP_ENV === 'test') {
    if (typeof window === 'undefined') {
      const { server } = await import('./server.js')
      server.listen()
      // Cypress initializes client side mocking
    } else if (!window.Cypress) {
      const { worker } = await import('./browser')
      worker.start()
    }
  }
}

initMocks()

export {}
