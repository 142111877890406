import { postHogInit } from '@/lib/client/posthog'
import { getLocale } from '@/lib/localeUtils'
import { ErrorBoundary } from '@sentry/nextjs'
import { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { useIsomorphicLayoutEffect } from 'react-use'

const SignalContext = createContext(undefined)

const errorMessage = (
  <>
    <p>Oops, An error has occurred!</p>
    <p>We are working on resolving the issue.</p>
  </>
)

const SignalContextProvider = ({ signalContext, children }) => {
  const [locale, setLocale] = useState('en-US')

  const savelocale = (locale) => {
    localStorage.setItem('locale', locale)
    setLocale(locale)
  }

  useEffect(() => {
    setLocale(getLocale(signalContext.reqLocale))
  }, [signalContext])

  useIsomorphicLayoutEffect(() => {
    postHogInit(signalContext)
  }, [signalContext])

  const [favoritesProperties, setFavoritesProperties] = useState()

  useEffect(() => {
    setFavoritesProperties(
      JSON.parse(localStorage.getItem('favoritesProperties') || '[]')
    )
  }, [])


  const exports = useMemo(() => {
    return {
      ...signalContext,
      locale,
      savelocale,
      favoritesProperties,
      setFavoritesProperties
    }
  }, [locale, favoritesProperties, signalContext])

  return (
    <SignalContext.Provider value={{ ...exports }}>
      <ErrorBoundary fallback={errorMessage}>{children}</ErrorBoundary>
    </SignalContext.Provider>
  )
}
export default SignalContextProvider

export const useSignalContext = () => {
  const context = useContext(SignalContext)
  if (context === undefined)
    throw new Error('Context must be defined within a provider')
  return context
}
