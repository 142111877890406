export const generateToken = (length = 15) => {
  // Generate random token with `length` amount of chars.
  // `36` is the max radix value `toString` accepts
  // `2` is the 3th char at the generated string(that looks like "0.u2c3erbg668")
  //  We could get any char after the dot.
  return [...Array(length)].map(() => Math.random().toString(36)[2]).join('')
}

// This is useful as a per session token to identify the user actions that happened
// in the close past, like 2 factor auth, channel connect etc
export const genSessionToken = () => generateToken()
export const genDeviceToken = () => generateToken(10)
export const genTransactionToken = () => generateToken(4)
