import getConfig from 'next/config'
import posthog from 'posthog-js'

export function capturePageView(pageName) {
  if (!pageName) return

  posthog.capture('SignalPageview', {
    $set: { SignalPage: pageName }
  })
}

export function captureSignalQuote(pageName, isSuccess) {
  if (!pageName) return

  posthog.capture('SignalQuote', {
    $set: {
      SignalQuoteStaus: isSuccess ? 'success' : 'failed',
      SignalPage: pageName
    }
  })
}

export function postHogInit({ featureFlags, posthogUserId }) {
  if (process.env.NODE_ENV === 'test') return
  const posthogApiKey = getConfig().publicRuntimeConfig.posthogApiKey

  if (posthogUserId) {
    posthog.init(posthogApiKey, {
      autocapture: false,
      bootstrap: {
        distinctID: posthogUserId,
        featureFlags
      }
    })
  }
}
