export const rootId = 'signal-be-client'

export const priceItemType = {
  TOTAL: 'total'
}

export const BRAND_WILDCARD = '[brand]'
export const RESERVATION = 'reservation'
export const isoDateFormat = 'YYYY-MM-DD'
export const validateEmailRegExp =
  // eslint-disable-next-line no-empty-character-class
  /([-!#-'*+/-9=?A-Z^-~]+(\.[-!#-'*+/-9=?A-Z^-~]+)*|"([]!#-[^-~ \t]|(\\[\t -~]))+")@[0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?(\.[0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?)+/
