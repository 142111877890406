export const NumberInput = {
  variants: {
    outline: {
      field: {
        border: '1px',
        backgroundColor: 'wpMain.bg2',
        borderColor: 'wpMain.border',
        _placeholder: { color: 'wpMain.color' },
        color: 'wpMain.color'
      },
      addon: {
        border: '1px',
        backgroundColor: 'wpMain.bg2',
        borderColor: 'wpMain.border'
      }
    }
  }
}
