import { capturePageView } from '@/lib/client/posthog'
import { useRouter } from 'next/router'
import React from 'react'

let lastTrackedPath

function track(path, page) {
  // avoid double tracking - see https://github.com/vercel/next.js/issues/11639
  if (path === lastTrackedPath) {
    return
  }

  lastTrackedPath = path

  capturePageView(page?.page_name)
}

export function useTrackPageViews(page) {
  const router = useRouter()

  React.useEffect(() => {
    if (router.isReady) {
      track(router.asPath, page)
    }
  }, [router.isReady, page, router.asPath])

  React.useEffect(() => {
    const onRouteChangeComplete = (asPath) => {
      track(asPath)
    }
    router.events.on('routeChangeComplete', onRouteChangeComplete)
    return () => {
      router.events.off('routeChangeComplete', onRouteChangeComplete)
    }
  }, [router.events])
}
