import { useSignalContext } from '@/contexts/signal'
import SignalLogo from '@/icons/signal-logo.svg'
import {
  ChevronDownIcon,
  ChevronRightIcon,
  CloseIcon,
  HamburgerIcon
} from '@chakra-ui/icons'
import {
  Box,
  Collapse,
  Flex,
  Icon,
  IconButton,
  Link,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Spacer,
  Stack,
  Text,
  useDisclosure
} from '@chakra-ui/react'

export default function Header() {
  const { isOpen, onToggle } = useDisclosure()
  const { token } = useSignalContext()

  return (
    <Box
      borderBottom={1}
      borderStyle={'solid'}
      borderColor="gray.300"
      pos="sticky"
      top="0"
      zIndex={15}
      id="header"
      bg="white"
    >
      <Box maxW="container.2xl" mx="auto" h="60px">
        <Flex
          color={'gray.600'}
          minH={'60px'}
          py={{ base: 2 }}
          px={{ base: 4 }}
          align={'center'}
          h="full"
        >
          <Box w="100px" h="100%" pos="relative">
            <Link href={`/${token}`}>
              <Icon as={SignalLogo} boxSize="full" />
            </Link>
          </Box>

          <Spacer />

          <Flex display={{ base: 'none', md: 'flex' }} ml={10}>
            <DesktopNav />
          </Flex>
          <Flex
            flex={{ base: 1, md: 'auto' }}
            ml={{ base: -2 }}
            display={{ base: 'flex', md: 'none' }}
          >
            <Spacer />
            <IconButton
              onClick={onToggle}
              icon={
                isOpen ? (
                  <CloseIcon w={3} h={3} />
                ) : (
                  <HamburgerIcon w={5} h={5} />
                )
              }
              variant={'ghost'}
              aria-label={'Toggle Navigation'}
            />
          </Flex>
        </Flex>

        <Box pos="absolute" zIndex="10" width="full">
          <Collapse in={isOpen} animateOpacity={true}>
            <MobileNav />
          </Collapse>
        </Box>
      </Box>
    </Box>
  )
}

const DesktopNav = () => {
  const linkColor = 'gray.600'
  const linkHoverColor = 'wpMain.primary'
  const popoverContentBgColor = 'white'

  return (
    <Stack direction={'row'} spacing={4}>
      {NAV_ITEMS.map((navItem) => (
        <Box key={navItem.label}>
          <Popover trigger={'hover'} placement={'bottom-start'}>
            <PopoverTrigger>
              <Link
                p={2}
                href={navItem.href ?? '#'}
                fontSize={'sm'}
                fontWeight={500}
                color={linkColor}
                _hover={{
                  textDecoration: 'none',
                  color: linkHoverColor
                }}
              >
                {navItem.label}
              </Link>
            </PopoverTrigger>

            {navItem.children && (
              <PopoverContent
                border={1}
                borderColor="gray.200"
                borderStyle="solid"
                boxShadow={'xl'}
                bg={popoverContentBgColor}
                p={4}
                rounded={'md'}
                minW={'sm'}
              >
                <Stack>
                  {navItem.children.map((child) => (
                    <DesktopSubNav key={child.label} {...child} />
                  ))}
                </Stack>
              </PopoverContent>
            )}
          </Popover>
        </Box>
      ))}
    </Stack>
  )
}

const DesktopSubNav = ({ label, href, subLabel }) => {
  return (
    <Link
      href={href}
      role={'group'}
      display={'block'}
      p={2}
      rounded={'md'}
      _hover={{ bg: 'white' }}
    >
      <Stack direction={'row'} align={'center'}>
        <Box>
          <Text
            transition={'all .3s ease'}
            _groupHover={{ color: 'wpMain.primary' }}
            fontWeight={500}
          >
            {label}
          </Text>
          <Text fontSize={'sm'}>{subLabel}</Text>
        </Box>
        <Flex
          transition={'all .3s ease'}
          transform={'translateX(-10px)'}
          opacity={0}
          _groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
          justify={'flex-end'}
          align={'center'}
          flex={1}
        >
          <Icon color={'wpMain.secondary'} w={5} h={5} as={ChevronRightIcon} />
        </Flex>
      </Stack>
    </Link>
  )
}

const MobileNav = () => {
  return (
    <Stack bg={'white'} p={4} display={{ md: 'none' }}>
      {NAV_ITEMS.map((navItem) => (
        <MobileNavItem key={navItem.label} {...navItem} />
      ))}
    </Stack>
  )
}

const MobileNavItem = ({ label, children, href }) => {
  const { isOpen, onToggle } = useDisclosure()

  return (
    <Stack spacing={4} onClick={children && onToggle}>
      <Flex
        py={2}
        as={Link}
        href={href ?? '#'}
        justify={'space-between'}
        align={'center'}
        _hover={{
          textDecoration: 'none'
        }}
      >
        <Text fontWeight={500} color={'gray.600'}>
          {label}
        </Text>
        {children && (
          <Icon
            as={ChevronDownIcon}
            transition={'all .25s ease-in-out'}
            transform={isOpen ? 'rotate(180deg)' : ''}
            w={6}
            h={6}
          />
        )}
      </Flex>

      <Collapse in={isOpen} animateOpacity style={{ marginTop: '0!important' }}>
        <Stack
          mt={2}
          pl={4}
          borderLeft={1}
          borderStyle={'solid'}
          borderColor={'gray.200'}
          align={'start'}
        >
          {children &&
            children.map((child) => (
              <Link key={child.label} py={2} href={child.href}>
                {child.label}
              </Link>
            ))}
        </Stack>
      </Collapse>
    </Stack>
  )
}

const NAV_ITEMS = [
  {
    label: 'Vacation Rentals',
    children: [
      {
        label: 'Search by Features',
        subLabel: 'Find properties by features',
        href: '#'
      },
      {
        label: 'Complexes',
        subLabel: 'Look for properties in our complexes',
        href: '#'
      },
      {
        label: 'Property Reviews',
        subLabel: 'Reviews from our customers',
        href: '#'
      }
    ]
  },
  {
    label: 'Specials',
    href: '#'
  },
  {
    label: 'Plan Your Experience',
    href: '#'
  },
  {
    label: 'About Us',
    href: '#'
  },
  {
    label: 'Blog',
    href: '#'
  }
]
