import createCache from '@emotion/cache'
import { CacheProvider } from '@emotion/react'
import PropTypes from 'prop-types'
import * as React from 'react'

const selectorPrefix = '#__next > #signal-be-client'
// const selectorPrefix = '';

const ROOT_SCOPE = ['html', 'body', ':host', ':root', '[data-js-focus-visible]']

export const key = 'sc'

/**
 * Creates a custom cache that has greater specificity for all CSS rules than what
 * comes from WordPress. This guarantees that no styles from WordPress overrides
 * Chakra's styles.
 *
 * Example of a generated rule:
 *    #__next > #signal-be-client .emotion_class { ... }
 */
export const emotionCache = createCache({
  key,
  stylisPlugins: [
    (element) => {
      if (element.type !== 'rule') {
        return
      }

      // @keyframes rules cannot be scoped
      if (element.root?.type === '@keyframes') {
        return
      }

      element.props = element.props.map((p) => {
        // Elements like html need to be suffixed not prefixed. e.g. html #myselector .myclass
        if (ROOT_SCOPE.find((e) => p.startsWith(e))) {
          const parts = p.split(' ')
          if (parts.length > 1) {
            return `${parts[0]} ${selectorPrefix} ${parts[1] || ''}`
          } else {
            return p
          }
        }

        // If the property is not yet prefixed
        if (!p.startsWith(selectorPrefix)) {
          return selectorPrefix + ' ' + p
        }

        // If no rule matches return the property unchanged
        return p
      })
    }
  ]
})

export const EmotionCacheProvider = (props) => {
  return <CacheProvider value={emotionCache}>{props.children}</CacheProvider>
}

EmotionCacheProvider.propTypes = {
  children: PropTypes.element.isRequired
}

const globalCache = createCache({
  key: 'scg'
})

export const GlobalCacheProvider = (props) => {
  return <CacheProvider value={globalCache}>{props.children}</CacheProvider>
}

GlobalCacheProvider.propTypes = {
  children: PropTypes.element.isRequired
}
