import Agent from 'agentkeepalive'
import axios from 'axios'
import { setupCache } from 'axios-cache-adapter'
import axiosRetry from 'axios-retry'
import getConfig from 'next/config'
import { createBaseApiClient, getBaseApi, getBeyondUrl } from '../baseApi'

const { serverRuntimeConfig } = getConfig()

const agentConfig = {
  maxSockets: 100,
  maxFreeSockets: 10,
  timeout: 60000,
  freeSocketTimeout: 30000
}

// Use a connection pool on server
axios.defaults.httpAgent = new Agent(agentConfig)
axios.defaults.httpsAgent = new Agent.HttpsAgent(agentConfig)

// Create a server side cache
const cache = setupCache({
  maxAge: 0 // Default to no cache
})

const createApiClient = (ctx) => {
  const axiosInstance = axios.create({
    baseURL: getBeyondUrl(serverRuntimeConfig.signalBeyondAppInternalUrl),
    adapter: cache.adapter
  })

  // Setup retries for server side api client
  axiosRetry(axiosInstance, {
    retries: 2,
    retryDelay: (retries, err) => {
      switch (err.code) {
        case '429': {
          return err.response.data.retry_after || retries * 1000
        }
        default: {
          return retries * 1000
        }
      }
    }
  })

  return createBaseApiClient(axiosInstance, ctx)
}

export const getApi = (ctx) => {
  const apiClient = createApiClient(ctx)
  return getBaseApi(apiClient, ctx)
}
