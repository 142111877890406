const styles = {
  global: {
    html: {
      height: '100%',
      scrollBehavior: 'smooth'
    }
  }
}

export default styles
