// const defaultFont = `"Open Sans", europa, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif`

const typography = {
  fonts: {
    heading: 'var(--default_font)',
    body: 'var(--default_font)'
  }
}

export default typography
