export const Textarea = {
  variants: {
    outline: {
      border: '1px',
      backgroundColor: 'wpMain.bg2',
      borderColor: 'wpMain.border',
      _placeholder: { color: 'wpMain.color' },
      color: 'wpMain.color'
    }
  }
}
